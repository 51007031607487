// This file contains details about the meter properties that this project will have. It was
// originally created to fix a circular dependency that existed between
// site-consts -> logger -> site-consts by removing all imports from site-consts.
import appLogger from './logger';

const mlogger = appLogger.getLogger('site-consts');
// mlogger.logLevel = mlogger.loggerLevels.debug;

// const API_BASE = 'https://vinton-meter-swap.carlsapp.com/api';
// const STORAGE_KEY = 'MeterData';
// const WINDOW_TITLE = 'Vinton Meter Swap';
// const APP_VERSION = '2.8';
const ROUTE_KEY = 'Route Name';
const NUM_DETAILS_SHOW = 3;

const PROP_TYPES = {
    BOOL: Boolean,
    STRING: String,
    NUMBER: Number,
    SELECT: 'Select',
    SELECT_MULTI: 'Select Multiple',
    SELECT_EDITABLE: 'Select Editable',
    BARCODE: 'Barcode',
    TEXTAREA: 'Text Area',
    DATE: 'Date',
    ISO_TIMESTAMP: 'ISO Timestamp',
    INSTALL_TYPE: 'Install Type',
    TIMER_TEXT: 'Timer Text',
    CREW_WITH_HOURS: 'Crew With Hours',
};

// const DEFAULT_IMPORT_FILE_FORMAT = 'Fixed Width';
const FEATURE_TOGGLES = {
    SHOW_GPS: false,
    // GPS_EDITABLE: true,
};

// The meter props is a list of all the properties we have for the meters
//   key - The meter property name that contains the value for this property meter[prop.key]
//   type - The type of the prop. Possible values: Boolean, String, Number
//   TODO Complete this list
const METER_PROPS = [
    { key: 'Location #', type: PROP_TYPES.NUMBER },
    { key: 'Customer #' },
    { key: 'Service Address', align: 'left' },
    { key: 'Route Name' },
    { key: 'Read Sequence', type: PROP_TYPES.NUMBER },
    { key: 'Outgoing Meter Number' },
    { key: 'Previous Reading', type: PROP_TYPES.NUMBER },
    { key: 'Meter Message' },
    {
        key: 'Outgoing Meter Reading',
        modifiable: true,
        type: PROP_TYPES.NUMBER,
        htmlType: 'number',
        // TODO Create a Meter Reading type eliminates this function
        validationFunc: function outgoingMeterReadingValid(value, meter) {
            if (!value) {
                return 'A value must be entered.';
            }
            if (value < meter['Previous Reading']) {
                return 'The meter reading is less than the previous reading.';
            }
            if (value > meter['Previous Reading'] + 1000) {
                return 'The meter reading shows a consumption of more than 1000 above the previous reading. Is it correct?';
            }
            // The current import file doesn't have a high consumption value, so we don't have a
            // way to check if the value is too high.
            // if (value > this.meter['Last Meter Reading'] + this.meter['High Consumption']) {
            //     return 'This meter consumed more than it has ever previously consumed. Are you
            // sure you read it correctly?';
            // }
            return true;
        },
    }, {
        key: 'Meter Size',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: ['5/8"', '3/4"', '1"', '1.5"', '2"', '3"', 'HYDRANT'],
    }, {
        key: 'Inlet Pipe Type',
        modifiable: true,
        optional: true,
        type: PROP_TYPES.SELECT,
        choices: ['Lead', 'Copper', 'Galvanize', 'Plastic', 'Unknown'],
    }, {
        key: 'Outlet Pipe Type',
        modifiable: true,
        optional: true,
        type: PROP_TYPES.SELECT,
        choices: ['Lead', 'Copper', 'Galvanize', 'Plastic', 'Unknown'],
    }, {
        key: 'New Meter Serial',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
        // validationFunc: function newMeterSerialValid(value) {
        //     if (!METER_ID_RE.test(value)) {
        //         return 'The new meter serial number is expected to be of the format 22003XXXX';
        //     }
        //     return true;
        // },
    }, {
        key: 'Transmitter ID',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
        // TODO Add a prop like validationExpr that will be the regular expression to test against
        // validationFunc: function transmitterIdValid(value) {
        //     if (!TRANSMITTER_ID_RE.test(value)) {
        //         return 'The transmitter ID is expected to be of the format 130XXXXXX';
        //     }
        //     return true;
        // },
    }, {
        key: 'Install Date',
        modifiable: true,
        type: PROP_TYPES.DATE,
        defaultValue: function dateDefault() {
            // The date component presents a default value. But, we need this function so the
            // save function can grab a default value for the save.
            const now = new Date();
            return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
        },
    },
    { key: 'Uninstallable', type: PROP_TYPES.BOOL, defaultValue: false, modifiable: true },
    {
        key: 'Town Attention Needed', type: PROP_TYPES.BOOL, defaultValue: false, modifiable: true,
    }, {
        key: 'Town Attention Needed Problem Type',
        modifiable: true,
        condition: function showProblemType(meter) {
            // Only show this field if they've selected Hourly Rate for the Install Type
            return meter['Town Attention Needed'] === true;
        },
        type: PROP_TYPES.SELECT,
        choices: ['Meter', 'Pipe Identification', 'Both'],
    },
    { key: 'Notes', modifiable: true, type: PROP_TYPES.TEXTAREA, optional: true },
    { key: 'Version', name: 'Last Updated', type: PROP_TYPES.ISO_TIMESTAMP },
];

const PHOTOS = [
    {
        name: 'Final Read',
        shortName: 'final-read',
        key: 'Photo URL Final Read',
    }, {
        name: 'Meter & Tx #',
        shortName: 'post-exchange',
        key: 'Photo URL Post Exchange',
    }, {
        name: 'Inlet Pipe',
        shortName: 'inlet-pipe',
        key: 'Photo URL Inlet Pipe',
        optional: true,
    }, {
        name: 'Outlet Pipe',
        shortName: 'outlet-pipe',
        key: 'Photo URL Outlet Pipe',
        optional: true,
    },
];

// Everything else is pre-computed arrays that can be overriden with custom arrays if needed

const METER_PROPS_BY_KEY = {};
const METER_PROPS_BY_NAME = {};
for (let i = 0; i < METER_PROPS.length; i++) {
    // Apply our default values
    if (METER_PROPS[i].name === undefined) {
        // If we didn't specify a name, the name is the same as the key
        METER_PROPS[i].name = METER_PROPS[i].key;
    }
    if (METER_PROPS[i].type === undefined) {
        // If we didn't specify a type, set as string. This is needed for sorting.
        METER_PROPS[i].type = PROP_TYPES.STRING;
    }
    // Text and value are used by the Vuetify data table header objects
    METER_PROPS[i].text = METER_PROPS[i].name;
    METER_PROPS[i].value = METER_PROPS[i].key;
    if (METER_PROPS[i].modifiable === undefined) {
        METER_PROPS[i].modifiable = false;
    }
    METER_PROPS_BY_KEY[METER_PROPS[i].key] = METER_PROPS[i];
    METER_PROPS_BY_NAME[METER_PROPS[i].name] = METER_PROPS[i];
}

const METER_DETAILS_PROPS = METER_PROPS.filter((prop) => !prop.modifiable);

const METER_EDIT_PROPS = METER_PROPS.filter((prop) => prop.modifiable);

const INITIAL_SORT_PROPS = [
    METER_PROPS_BY_KEY['Route Name'],
    METER_PROPS_BY_KEY['Read Sequence'],
];

const METER_LIST_COLS = [
    METER_PROPS_BY_KEY['Read Sequence'],
    METER_PROPS_BY_KEY['Outgoing Meter Number'],
    METER_PROPS_BY_KEY['Meter Size'],
    METER_PROPS_BY_KEY['Service Address'],
];
const PHOTOS_BY_TYPE = {};
for (let i = 0; i < PHOTOS.length; i++) {
    PHOTOS_BY_TYPE[PHOTOS[i].shortName] = PHOTOS[i];
}

// To use this file in a CommonJS environment replace export with module.exports =.
export {
    ROUTE_KEY, PROP_TYPES,
    FEATURE_TOGGLES, METER_PROPS, METER_PROPS_BY_KEY,
    METER_PROPS_BY_NAME, METER_DETAILS_PROPS, NUM_DETAILS_SHOW, METER_EDIT_PROPS,
    INITIAL_SORT_PROPS, METER_LIST_COLS, PHOTOS, PHOTOS_BY_TYPE,
};
